const twelve = 12;

export const roleTransforme = (key: string): string => {
  switch (key) {
    case 'IMPERSONATE-SUPER-ADMIN':
      return 'SUPER ADMIN';
    case 'IMPERSONATE-ADMIN':
      return 'ADMIN';
    case 'IMPERSONATE-GENERAL':
      return 'GENERAL';
    default:
      return '';
  }
};

// Função para converter uma string em um ArrayBuffer
export const convertArrayBuffer = (str: string): ArrayBuffer => {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
};

// Função para converter um ArrayBuffer em uma string
export const convertString = (buf: ArrayBuffer): string => {
  return Array.from(new Uint8Array(buf))
    .map(byte => String.fromCharCode(byte))
    .join('');
};

export const encryptRole = async (role: string, secretKey: string): Promise<string> => {
  const enc = new TextEncoder();
  const roleTransformed = roleTransforme(role);
  const key = await crypto.subtle.importKey('raw', enc.encode(secretKey), { name: 'AES-GCM' }, false, ['encrypt']);
  const iv = crypto.getRandomValues(new Uint8Array(twelve));
  const encrypted = await crypto.subtle.encrypt({ name: 'AES-GCM', iv: iv }, key, enc.encode(roleTransformed));
  return `${convertString(iv.buffer)}:${convertString(encrypted)}`;
};

export const decryptRole = async (encryptedRole: string, secretKey: string): Promise<string> => {
  const [ivStr, encryptedStr] = encryptedRole.split(':');
  const enc = new TextEncoder();
  const key = await crypto.subtle.importKey('raw', enc.encode(secretKey), { name: 'AES-GCM' }, false, ['decrypt']);
  const iv = convertArrayBuffer(ivStr);
  const encrypted = convertArrayBuffer(encryptedStr);
  const decrypted = await crypto.subtle.decrypt({ name: 'AES-GCM', iv: iv }, key, encrypted);
  return new TextDecoder().decode(decrypted);
};
