/* istanbul ignore file */
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import { shouldMockApollo } from '@services/apollo';

declare global {
  interface Window {
    config: {
      TextEditorKey: string;
      FasterK: string;
      FasterS: string;
      FasterAppKey: string;
      AdminAPI: string;
      AdminGraphQL: string;
      StaticAssetsURL: string;
      ImpersonateRole: string;
    };
  }
}

if (process.env.NODE_ENV === `development`) {
  window.config = {} as any;
  window.config.StaticAssetsURL = process.env.REACT_APP_URL_STATIC as string;
  window.config.AdminAPI = process.env.REACT_APP_URL_ADMIN_API as string;
  window.config.AdminGraphQL = process.env.REACT_APP_URL_GRAPHQL as string;
}

const URL_PARAM_INDEX = 1;
const oldXHROpen = window.XMLHttpRequest.prototype.open;
// @ts-ignore
window.XMLHttpRequest.prototype.open = function (...args) {
  const params = args;
  const requestUrl = params[URL_PARAM_INDEX];
  const isTokenUrl = requestUrl === 'https://shop.ifood.com.br/auth/realms/ifood/protocol/openid-connect/token';

  params[URL_PARAM_INDEX] = isTokenUrl ? requestUrl.replace('shop.ifood', 'shop-admin.ifood') : requestUrl;

  //@ts-ignore
  return oldXHROpen.apply(this, params);
};

if (shouldMockApollo) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker().start({
    serviceWorker: {
      url: '/mockServiceWorker.js',
    },
  });
}

const rootNode = document.getElementById('root');
ReactDOM.render(<App />, rootNode);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
