import { useMutation } from '@apollo/react-hooks';
import Cookies from 'js-cookie';
import { CustomerImpersonateDataT } from '../types';
import { IMPERSONATE_CUSTOMER } from '@pages/Customers/bff/mutations/impersonateCustomer';
import { snackbar } from '@ifood/pomodoro-components';

const useCustomerImpersonate = (customerId: number, roleImpersonate: string) => {
  const [impersonateCustomer, { loading: loadingImpersonate }] = useMutation(IMPERSONATE_CUSTOMER, {
    onCompleted: ({ impersonateCustomer }) =>
      impersonateCustomer?.resultType === 'SUCCESS' ? onSuccessCallback(impersonateCustomer) : onErrorCallback(),
    onError: () => onErrorCallback(),
  });

  const onSuccessCallback = (dataImpersonate: CustomerImpersonateDataT) => {
    const { accessToken, refreshToken } = dataImpersonate.result.data;
    if (accessToken) {
      // Remover cookies antigos
      Cookies.remove('establishmentId', { domain: '.ifood.com.br' });
      Cookies.remove('postalCode', { domain: '.ifood.com.br' });
      Cookies.remove('kc_token', { domain: '.ifood.com.br' });
      Cookies.remove('kc_refreshToken', { domain: '.ifood.com.br' });
      Cookies.remove('kc_context_impersonate', { domain: '.ifood.com.br' });
      Cookies.remove('role_user_mayoral', { domain: '.ifood.com.br' });

      // Definir novos cookies com o domínio principal
      Cookies.set('kc_token', accessToken, { domain: '.ifood.com.br', path: '/', secure: true, sameSite: 'None' });
      Cookies.set('kc_refreshToken', refreshToken, {
        domain: '.ifood.com.br',
        path: '/',
        secure: true,
        sameSite: 'None',
      });
      Cookies.set('kc_context_impersonate', '1', {
        domain: '.ifood.com.br',
        path: '/',
        secure: true,
        sameSite: 'None',
      });
      Cookies.set('role_impersonate', roleImpersonate, {
        domain: '.ifood.com.br',
        path: '/',
        secure: true,
        sameSite: 'None',
      });
      Cookies.set('user_impersonate', 'true', { domain: '.ifood.com.br', path: '/', secure: true, sameSite: 'None' });

      window.open('https://shop.ifood.com.br/sso?utm_source=mayoral&utm_medium=interno', '_blank', 'noopener');
    }
  };

  const onErrorCallback = () => {
    snackbar({ message: 'Algo deu errado, não foi possível no momento', variant: 'error', timeout: 5000 });
  };
  const handleCustomerMode = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    impersonateCustomer({
      variables: { customerId },
    });
  };

  return {
    handleCustomerMode,
    loadingImpersonate,
  };
};

export default useCustomerImpersonate;
