import gql from 'graphql-tag';

const GET_ALL_CUSTOMERSGROUP = gql`
  query getAllCustomerGroups($page: Int, $pageSize: Int, $groupName: String, $state: Boolean) {
    getAllCustomerGroups(page: $page, size: $pageSize, name: $groupName, active: $state) {
      content {
        id
        name
        active
      }
      totalPages
      number
    }
  }
`;

const GET_CUSTOMER_LIST = gql`
  query getCustomerList(
    $id: ID
    $nome: String
    $document: String
    $login: String
    $page: Int
    $size: Int
    $type: String
  ) {
    getCustomerList(id: $id, nome: $nome, document: $document, login: $login, page: $page, size: $size, type: $type) {
      content {
        id
        identityProviderId
        name
        document
        login
        email
        mobilePhoneDialCode
        mobilePhone
        iderp
        kind
        isFraudster
        isNewCustomer
        isAuthenticated
        isFunctionary
        createdAt
        updatedAt
      }
      pageable {
        sort {
          empty
          sorted
          unsorted
        }
        paged
        pageSize
        pageNumber
        unpaged
      }
      totalPages
    }
  }
`;

const GET_CUSTOMERGROUP = gql`
  query getCustomerGroup($idGroup: ID!) {
    getCustomerGroup(groupId: $idGroup) {
      name
      description
      active
    }
  }
`;

const GET_CUSTOMERS = gql`
  query getAllCustomerGroupCustomers($pageRequest: Int, $pageSize: Int, $valueSrcInput: String, $idGroup: ID!) {
    getAllCustomerGroupCustomers(page: $pageRequest, size: $pageSize, login: $valueSrcInput, groupId: $idGroup) {
      content {
        id
        name
        login
        document
      }
      totalPages
    }
  }
`;

const DELETE_CUSTOMER = gql`
  mutation deleteCustomerGroupCustomer($idGroup: ID!, $listCustomerId: [ID!]!) {
    deleteCustomerGroupCustomer(groupId: $idGroup, customerIds: $listCustomerId) {
      id
      name
      login
    }
  }
`;

const EXPORT_LIST = gql`
  query getCustomerGroup($idGroup: ID!) {
    getCustomerGroup(groupId: $idGroup) {
      name
      description
      active
    }
  }
`;

const ADD_CUSTOMERS = gql`
  mutation createCustomerGroupCustomer($idGroupValue: ID!, $document: String!) {
    createCustomerGroupCustomer(groupId: $idGroupValue, customerDocument: $document) {
      statusCode
      customers {
        id
        name
        login
      }
    }
  }
`;

const GET_CUSTOMERLISTBYEMAIL = gql`
  query getCustomerListByEmail($email: String, $page: Int) {
    getCustomerListByEmail(email: $email, page: $page) {
      id
      name
      email
    }
  }
`;

const GET_LIFECYCLE_TYPES_BY_NAME = gql`
  query getLifecycleTypesByName($name: String!) {
    getLifecycleTypesByName(name: $name) {
      id
      name
    }
  }
`;

export {
  GET_ALL_CUSTOMERSGROUP,
  GET_CUSTOMER_LIST,
  GET_CUSTOMERGROUP,
  GET_CUSTOMERS,
  DELETE_CUSTOMER,
  EXPORT_LIST,
  ADD_CUSTOMERS,
  GET_CUSTOMERLISTBYEMAIL,
  GET_LIFECYCLE_TYPES_BY_NAME,
};
