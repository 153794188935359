import gql from 'graphql-tag';

const IMPERSONATE_CUSTOMER = gql`
  mutation impersonateCustomer($customerId: ID!) {
    impersonateCustomer(customerId: $customerId) {
      ... on ImpersonateToken {
        resultType
        result {
          data {
            accessToken
            refreshToken
          }
        }
      }
      ... on CustomError {
        code
        error
        message
      }
    }
  }
`;

export { IMPERSONATE_CUSTOMER };
