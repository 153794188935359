import { Flex, Heading1 } from '@ifood/pomodoro-components';
import { useHistory } from 'react-router-dom';
import * as S from '../../CustomerDetail.styles';
import CustomerFraudReportModal from '@pages/Customers/CustomerDetail/components/CustomerDetailHeader/CustomerFraudReportModal';
import CustomerFraudFinishModal from '@pages/Customers/CustomerDetail/components/CustomerDetailHeader/CustomerFraudFinishModal';
import CustomerRemoveFraudModal from './CustomerRemoveFraudModal';
import { useCustomerFraudModalsHook } from './customerFraudModalsHook';
import { CustomerFraudReportType } from './CustomerFraudReportModal/schema';
import Loading from '@components/Loading';
import { CustomerDetailHeaderT } from './types';
import useCustomerImpersonate from './hooks/useCustomerImpersonate';
import { getRoles } from '@helpers/rolesUtils';
import { ImpersonateRoles } from '@helpers/RoleGroups';
import { encryptRole } from './helpers/roleHelpImpersonate';
import { useEffect, useState } from 'react';

const ZERO = 0;

export function CustomerDetailHeader({
  onCancel,
  onEdit,
  isEditMode,
  isSavingContact,
  isFormValid,
  customerData,
}: CustomerDetailHeaderT) {
  const {
    modalFraudReport,
    setModalFraudReport,
    modalFraudFinishReport,
    setModalFraudFinishReport,
    modalFraudRemove,
    setModalFraudRemove,
    onSubmitFraudReport,
    modalFraudData,
    setModalFraudData,
  } = useCustomerFraudModalsHook();
  const history = useHistory();
  const roles = getRoles();

  const secretKey = process.env.REACT_APP_SECRET_KEY_ROLE_IMPERSONATE || window?.config?.ImpersonateRole;
  const impersonateRoles = roles.filter((item: string) => ImpersonateRoles.includes(item));
  const [impersonateRoleEncrypted, setImpersonateRoleEncrypted] = useState<string>('');

  useEffect(() => {
    const fetchEncryptedRole = async () => {
      if (impersonateRoles.length) {
        const encryptedRole = await encryptRole(impersonateRoles[ZERO], secretKey);
        setImpersonateRoleEncrypted(encryptedRole);
      }
    };
    fetchEncryptedRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secretKey]);

  const CustomerId = customerData?.id ?? ZERO;

  const { handleCustomerMode, loadingImpersonate } = useCustomerImpersonate(CustomerId, impersonateRoleEncrypted);

  const handleClickFraud = (data: CustomerFraudReportType) => {
    setModalFraudData(data);
    setModalFraudFinishReport(true);
    setModalFraudReport(false);
  };

  const handleCloseFinish = () => {
    setModalFraudFinishReport(false);
  };

  const handleSubmitFinish = () => {
    onSubmitFraudReport(modalFraudData);
  };

  const handleCloseRemove = () => {
    setModalFraudRemove(false);
  };

  const handleCloseReport = () => {
    setModalFraudReport(false);
  };

  function onBack() {
    history.push('/clientes/listagem-clientes');
  }

  return (
    <>
      <S.GoBackArrow data-testid="back-button" type="button" onClick={onBack} />
      <S.Header>
        <Flex>
          <Heading1 fontWeight="medium" my={10} variant="regular">
            Clientes
          </Heading1>
        </Flex>

        {isEditMode && (
          <Flex alignItems="center" justifyContent="flex-end">
            <>
              <S.ButtonCancel variant="tertiary" onClick={onCancel}>
                Cancelar
              </S.ButtonCancel>
              <S.ButtonSave disabled={isSavingContact || !isFormValid} isLoading={isSavingContact} type="submit">
                Salvar
              </S.ButtonSave>
            </>
          </Flex>
        )}

        {!isEditMode && (
          <Flex alignItems="center" justifyContent="flex-end">
            {!!impersonateRoles.length && (
              <S.ButtonSave variant="secondary" onClick={handleCustomerMode}>
                Comprar como cliente
              </S.ButtonSave>
            )}
            <S.ButtonCancel variant="secondary" onClick={onEdit}>
              Editar dados
            </S.ButtonCancel>
            {customerData?.hasActiveFraud ? (
              <S.ButtonSave type="button" onClick={() => setModalFraudRemove(true)}>
                Remover Bloqueio
              </S.ButtonSave>
            ) : (
              <S.ButtonSave type="button" onClick={() => setModalFraudReport(true)}>
                Relatar fraude
              </S.ButtonSave>
            )}
          </Flex>
        )}
      </S.Header>

      <CustomerFraudReportModal isOpen={modalFraudReport} onClose={handleCloseReport} onNext={handleClickFraud} />

      <CustomerFraudFinishModal
        isOpen={modalFraudFinishReport}
        onClose={handleCloseFinish}
        onFinish={handleSubmitFinish}
      />

      <CustomerRemoveFraudModal isOpen={modalFraudRemove} onClose={handleCloseRemove} />
      {loadingImpersonate && <Loading />}
    </>
  );
}
