const Roles = {
  VARIATION_WR: 'VARIATION-WR',
  VARIATION_RO: 'VARIATION-RO',
  OFFER_WR: 'OFFER-WR',
  OFFER_RO: 'OFFER-RO',
  PRODUCT_WR: 'PRODUCT-WR',
  PRODUCT_RO: 'PRODUCT-RO',
  REGION_WR: 'REGION-WR',
  REGION_RO: 'REGION-RO',
  CATEGORY_WR: 'CATEGORY-WR',
  CATEGORY_RO: 'CATEGORY-RO',
  COMMERCIAL_CONDITION_WR: 'COMMERCIAL-CONDITION-WR',
  COMMERCIAL_CONDITION_RO: 'COMMERCIAL-CONDITION-RO',
  CUSTOMER_WR: 'CUSTOMER-WR',
  CUSTOMER_RO: 'CUSTOMER-RO',
  BRAND_WR: 'BRAND-WR',
  BRAND_RO: 'BRAND-RO',
  SELLER_ADMIN_SHOP: 'SELLER-ADMIN-SHOP',
  SELLER_WR: 'SELLER-WR',
  SELLER_RO: 'SELLER-RO',
  DISCOUNT_WR: 'DISCOUNT-WR',
  DISCOUNT_RO: 'DISCOUNT-RO',
  VOUCHER_RO: 'VOUCHER-RO',
  VOUCHER_WR: 'VOUCHER-WR',
  ORDER_RO: 'ORDER-RO',
  ORDER_WR: 'ORDER-WR',
  FIXEDPRICE_RO: 'FIXEDPRICE-RO',
  FIXEDPRICE_WR: 'FIXEDPRICE-WR',
  COLLECTION_RO: 'COLLECTION-RO',
  COLLECTION_WR: 'COLLECTION-WR',
  SUGGESTION_APR: 'SUGGESTION-APR',
  SUGGESTION_WR: 'SUGGESTION-WR',
  SUGGESTION_RO: 'SUGGESTION-RO',
  PRICE_MINIMUM_WR: 'PRICE-MINIMUM-WR',
  PRICE_MINIMUM_RO: 'PRICE-MINIMUM-RO',
  DISTRIBUTION_ADDRESS_WR: 'DISTRIBUTION-ADDRESS-WR',
  DISTRIBUTION_ADDRESS_RO: 'DISTRIBUTION-ADDRESS-RO',
  DASHBOARD_RO: 'DASHBOARD-RO',
  DASHBOARD_WR: 'DASHBOARD-WR',
  DATA_SELLER_RO: 'DATA-SELLER-RO',
  DATA_SELLER_WR: 'DATA-SELLER-WR',
  BAD_WORDS_RO: 'BAD-WORDS-RO',
  BAD_WORDS_WR: 'BAD-WORDS-WR',
  COMMISSION_RO: 'COMMISSION-RO',
  COMMISSION_WR: 'COMMISSION-WR',
  LOGISTICS_ALLOWED_WR: 'LOGISTICS-ALLOWED-WR',
  LOGISTICS_ALLOWED_RO: 'LOGISTICS-ALLOWED-RO',
  CARRIER_WR: 'CARRIER-WR',
  CARRIER_RO: 'CARRIER-RO',
  FINANCIAL_WR: 'FINANCIAL-WR',
  RUPTURA_LOTE_WR: 'RUPTURA-LOTE-WR',
  CANCEL_ORDER: 'CANCEL-ORDER',
  CAD_SELLER_WR: 'CAD-SELLER-WR',
  CAD_SELLER_RO: 'CAD-SELLER-RO',
  PRICE_KG_SELLER_WR: 'PRICE-KG-SELLER-WR',
  PRICE_KG_SELLER_RO: 'PRICE-KG-SELLER-RO',
  RUPTURE_PAYMENT_PROCESSED: 'RUPTURE-PAYMENT-PROCESSED',
  COUPON_BATCH_WR: 'COUPON-BATCH-WR',
  COUPON_BATCH_RO: 'COUPON-BATCH-RO',
  CAD_CUSTOMER_WR: 'CAD-CUSTOMER-WR',
  CAD_CUSTOMER_RO: 'CAD-CUSTOMER-RO',
  SHOPPING_LIST_RO: 'SHOPPING_LIST-RO',
  SHOPPING_LIST_WR: 'SHOPPING_LIST-WR',
  ESTABLISHMENT_PAYMENT_TYPES_RO: 'ESTABLISHMENT-PAYMENT-TYPES-RO',
  ESTABLISHMENT_PAYMENT_TYPES_WR: 'ESTABLISHMENT-PAYMENT-TYPES-WR',
  SINTEGRAWS_BY_PASS_RO: 'SINTEGRAWS-BY-PASS-RO',
  SINTEGRAWS_BY_PASS_WR: 'SINTEGRAWS-BY-PASS-WR',
  SELLER_OWNER_WR: 'SELLER-OWNER-WR',
  ORDER_OWNER_WR: 'ORDER-OWNER-WR',
  CUSTOMER_OWNER_WR: 'CUSTOMER-OWNER-WR',
  BONUS_SHOP_IMPORT: 'BONUS-SHOP-IMPORT',
  POST_PAID_BANK_SLIP_IMPORT: 'POST-PAID-BANK-SLIP-IMPORT',
  IMPERSONATE_GENERAL: 'IMPERSONATE-GENERAL',
  IMPERSONATE_ADMIN: 'IMPERSONATE-ADMIN',
  IMPERSONATE_SUPER_ADMIN: 'IMPERSONATE-SUPER-ADMIN',
};

module.exports = Roles;
